// src/plugins/analytics/index.js
import VueGtag from 'vue-gtag'

export default {
install(Vue, options) {
  // Basic configuration
  const GA_CONFIG = {
    local: {
      measurementId: 'G-ZTQLMDTSZN',
      appName: 'Deliver - Local'
    },
    stage: {
      measurementId: 'G-2HXLQJ774K',
      appName: 'Deliver - Stage'
    },
    production: {
      measurementId: 'G-42XJLRH3GR',
      appName: 'Deliver - Production'
    }
  }

  const CONSENT_STORAGE_KEY = 'analytics_consent_status'
  const USER_ID_STORAGE_KEY = 'analytics_userid_enabled'

  const CONSENT_TYPES = {
    ANALYTICS: 'analytics_storage',
    PERSONALIZATION: 'personalization_storage',
    AD_STORAGE: 'ad_storage',
    FUNCTIONALITY: 'functionality_storage'
  }

  // Event categories and names for consistency
  const EVENT_CATEGORIES = {
    USER: 'user',
    ENGAGEMENT: 'engagement',
    ERROR: 'error',
    NAVIGATION: 'navigation',
    SYSTEM: 'system'
  }

  const EVENT_NAMES = {
    PAGE_VIEW: 'page_view',
    LOGIN: 'login',
    LOGOUT: 'logout',
    CAMPAIGN_VIEW: 'campaign_view',
    TAB_CHANGE: 'tab_interaction',
    ERROR: 'error',
    PERFORMANCE: 'performance_metric'
  }

  // Environment detection
  const getCurrentEnvironment = () => {
    const hostname = window.location.hostname
    if (/localhost/.test(hostname)) return 'local'
    if (/stage\.deliverdooh\.com/.test(hostname)) return 'stage'
    if (/(deliver|verify)\.dooh\.com/.test(hostname)) return 'production'
    return 'local'
  }

  // Initialize environment config early
  const currentEnv = getCurrentEnvironment()
  const envConfig = GA_CONFIG[currentEnv]

  if (!envConfig?.measurementId) {
    console.error('Analytics initialization failed: invalid configuration')
    return
  }

  // Consent management with privacy-first approach
  const getConsentStatus = () => {
    try {
      return localStorage.getItem(CONSENT_STORAGE_KEY) === 'granted'
    } catch {
      return false
    }
  }

  const isUserIdTrackingEnabled = () => {
    try {
      return localStorage.getItem(USER_ID_STORAGE_KEY) === 'true'
    } catch {
      return false
    }
  }

  const setConsentStatus = (status) => {
    try {
      localStorage.setItem(CONSENT_STORAGE_KEY, status)
      window.gtag('consent', 'update', {
        [CONSENT_TYPES.ANALYTICS]: status
      })
      return true
    } catch (error) {
      console.error('Error setting consent status:', error)
      return false
    }
  }

  const enableUserIdTracking = () => {
    try {
      localStorage.setItem(USER_ID_STORAGE_KEY, 'true')
      const user = options.store?.state.User.user
      
      window.gtag('consent', 'update', {
        [CONSENT_TYPES.PERSONALIZATION]: 'granted'
      })

      if (user?.id) {
        window.gtag('config', envConfig.measurementId, {
          user_id: user.id.toString()
        })
        
        window.gtag('set', 'user_properties', {
          role_id: user.roleId,
          role_name: user.roleName,
          stakeholder_id: user.stakeholderId,
          stakeholder_name: user.stakeholderName
        })
      }
      
      return true
    } catch (error) {
      console.error('Error enabling user ID tracking:', error)
      return false
    }
  }

  const disableUserIdTracking = () => {
    try {
      localStorage.setItem(USER_ID_STORAGE_KEY, 'false')
      
      window.gtag('consent', 'update', {
        [CONSENT_TYPES.PERSONALIZATION]: 'denied'
      })

      window.gtag('config', envConfig.measurementId, {
        user_id: undefined
      })
      
      window.gtag('set', 'user_properties', null)
      
      return true
    } catch (error) {
      console.error('Error disabling user ID tracking:', error)
      return false
    }
  }

  // Initialize gtag and set default consent
  window.dataLayer = window.dataLayer || []
  window.gtag = function(){dataLayer.push(arguments)}
  window.gtag('js', new Date())
  
  window.gtag('consent', 'default', {
    [CONSENT_TYPES.ANALYTICS]: 'denied',
    [CONSENT_TYPES.PERSONALIZATION]: 'denied',
    [CONSENT_TYPES.AD_STORAGE]: 'denied',
    [CONSENT_TYPES.FUNCTIONALITY]: 'denied',
    wait_for_update: 500
  })

  // Error tracking
  const errorCache = new Set()
  const ERROR_CACHE_TIMEOUT = 1000 * 60 * 5 // 5 minutes

  const trackError = (error, context = {}) => {
    if (!getConsentStatus()) return Promise.resolve()

    const errorKey = `${error.message}:${error.stack}`
    if (errorCache.has(errorKey)) return Promise.resolve()

    errorCache.add(errorKey)
    setTimeout(() => errorCache.delete(errorKey), ERROR_CACHE_TIMEOUT)

    const user = options.store?.state.User.user
    return window.gtag('event', EVENT_NAMES.ERROR, {
      error_name: error.name,
      error_message: error.message,
      error_stack: error.stack,
      error_context: JSON.stringify(context),
      fatal: Boolean(context.fatal),
      user_id: isUserIdTrackingEnabled() ? user?.id?.toString() : undefined
    })
  }

  // User tracking
  const setUserProperties = (user) => {
    if (!user?.id || !getConsentStatus() || !isUserIdTrackingEnabled()) {
      return Promise.resolve()
    }

    return Promise.all([
      window.gtag('config', envConfig.measurementId, {
        user_id: user.id.toString()
      }),

      window.gtag('set', 'user_properties', {
        deliver_user_id: user.id.toString(),
        role_id: user.roleId || null,
        role_name: user.roleName || null,
        stakeholder_id: user.stakeholderId || null,
        stakeholder_name: user.stakeholderName || null
      }),

      // Send login event with GA4 recommended parameters
      window.gtag('event', 'login', {
        method: 'internal',
        user_id: user.id.toString(),
        role_id: user.roleId,
        stakeholder_id: user.stakeholderId
      })
    ])
  }

  const clearUserProperties = (oldUser) => {
    if (!getConsentStatus()) return Promise.resolve()

    return Promise.all([
      oldUser?.id && window.gtag('event', EVENT_NAMES.LOGOUT, {
        method: 'internal',
        user_id: oldUser.id.toString(),
        role_id: oldUser.roleId,
        stakeholder_id: oldUser.stakeholderId
      }),

      window.gtag('config', envConfig.measurementId, {
        user_id: undefined
      }),

      window.gtag('set', 'user_properties', {
        role_id: null,
        role_name: null,
        stakeholder_id: null,
        stakeholder_name: null
      })
    ])
  }

  // Campaign tracking utilities
  const shouldTrackPath = (path, fullPath) => {
    const rootPaths = ['', '/', '/#/']
    return !rootPaths.includes(path) && !rootPaths.includes(fullPath)
  }

  const isCampaignRoute = (route) => {
    return route.path.includes('/campaigns/campaign')
  }

  const getCampaignIdFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
      return urlParams.get('cid')
    } catch {
      return null
    }
  }

  // Campaign data waiting with timeout
  const waitForCampaign = (store, campaignId, maxWaitTime = 10000) => {
    return new Promise((resolve, reject) => {
      const currentCampaign = store.state.CurrentCampaign.campaign
      if (currentCampaign?.id === parseInt(campaignId)) {
        resolve(currentCampaign)
        return
      }

      const unwatch = store.watch(
        state => state.CurrentCampaign.campaign,
        newCampaign => {
          if (newCampaign?.id === parseInt(campaignId)) {
            unwatch()
            resolve(newCampaign)
          }
        }
      )

      setTimeout(() => {
        unwatch()
        reject(new Error(`Campaign ${campaignId} not found after ${maxWaitTime}ms`))
      }, maxWaitTime)
    })
  }

  // Enhanced page view tracking with campaign support
  const sendPageView = (to, from, campaignData = null) => {
    if (!shouldTrackPath(to.path, to.fullPath)) {
      console.debug('Skipping page view tracking for root/empty path:', to.path)
      return Promise.resolve()
    }

    if (!getConsentStatus()) return Promise.resolve()

    return new Promise((resolve, reject) => {
      try {
        const user = options.store?.state.User.user
        const pageData = {
          page_title: to.name,
          page_path: to.fullPath,
          page_location: window.location.href,
          page_referrer: document.referrer,
          navigation_type: from ? 'internal' : 'external',
          previous_page: from?.fullPath || null,
          user_id: isUserIdTrackingEnabled() ? user?.id?.toString() : undefined
        }

        if (isCampaignRoute(to) && campaignData) {
          const campaignTitle = `${campaignData.name} - ${to.name}`
          Object.assign(pageData, {
            page_title: campaignTitle,
            campaign_id: campaignData.id,
            campaign_name: campaignData.name,
            campaign_status: campaignData.workflowStatus?.as,
            view_type: to.query.tab || 'default'
          })

          if (from && 
              to.path === from.path && 
              to.query.cid === from.query.cid && 
              to.query.tab !== from.query.tab) {
            window.gtag('event', EVENT_NAMES.TAB_CHANGE, {
              event_category: EVENT_CATEGORIES.ENGAGEMENT,
              page_title: campaignTitle,
              campaign_id: campaignData.id,
              campaign_name: campaignData.name,
              campaign_status: campaignData.workflowStatus?.as,
              previous_tab: from.query.tab || 'default',
              new_tab: to.query.tab || 'default',
              user_id: isUserIdTrackingEnabled() ? user?.id?.toString() : undefined
            })
            resolve()
            return
          }

          window.gtag('event', EVENT_NAMES.CAMPAIGN_VIEW, pageData)
        }

        window.gtag('event', EVENT_NAMES.PAGE_VIEW, pageData)
        resolve()
      } catch (error) {
        console.error('Error in page view tracking:', error)
        reject(error)
      }
    })
  }

  // Initialize vue-gtag
  Vue.use(VueGtag, {
    config: {
      id: envConfig.measurementId,
      params: {
        send_page_view: false,
        debug_mode: process.env.NODE_ENV !== 'production'
      }
    },
    appName: envConfig.appName,
    pageTrackerEnabled: false,
    enabled: true,
    bootstrap: true,
    onReady: () => {
      const user = options.store?.state.User.user
      if (user?.id && getConsentStatus() && isUserIdTrackingEnabled()) {
        setUserProperties(user)
      }
    }
  }, options.router)

  // Expose analytics API
  Vue.prototype.$analytics = {
    grantConsent() {
      const result = setConsentStatus('granted')
      if (result) {
        window.gtag('consent', 'update', {
          [CONSENT_TYPES.ANALYTICS]: 'granted',
          [CONSENT_TYPES.FUNCTIONALITY]: 'granted'
        })
        
        if (isUserIdTrackingEnabled()) {
          enableUserIdTracking()
        }
      }
      return result
    },
    
    revokeConsent() {
      if (setConsentStatus('denied')) {
        window.gtag('consent', 'update', {
          [CONSENT_TYPES.ANALYTICS]: 'denied',
          [CONSENT_TYPES.PERSONALIZATION]: 'denied',
          [CONSENT_TYPES.AD_STORAGE]: 'denied',
          [CONSENT_TYPES.FUNCTIONALITY]: 'denied'
        })
        
        document.cookie.split(';').forEach(cookie => {
          const [name] = cookie.split('=')
          if (name.trim().startsWith('_ga')) {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${window.location.hostname}`
          }
        })
        
        disableUserIdTracking()
      }
    },
    
    getConsentStatus,
    enableUserIdTracking,
    disableUserIdTracking,
    isUserIdTrackingEnabled,

    trackEvent(eventName, params = {}) {
      if (!getConsentStatus()) return Promise.resolve()
      
      const user = options.store?.state.User.user
      return window.gtag('event', eventName, {
        ...params,
        user_id: isUserIdTrackingEnabled() ? user?.id?.toString() : undefined
      })
    }
  }

  // Watch for user changes
  if (options.store) {
    const userWatcher = options.store.watch(
      state => state.User.user,
      (newUser, oldUser) => {
        if (!getConsentStatus()) return
        
        if (newUser?.id && (!oldUser || newUser.id !== oldUser.id)) {
          if (isUserIdTrackingEnabled()) {
            setUserProperties(newUser)
          }
        } else if (oldUser?.isAuthed && !newUser?.isAuthed) {
          clearUserProperties(oldUser)
        }
      },
      { deep: true, immediate: true }
    )

    // Set up error tracking
    if (typeof window !== 'undefined') {
      window.addEventListener('error', (event) => {
        if (!getConsentStatus()) return
        
        trackError(event.error || new Error(event.message), {
          type: 'window_error',
          filename: event.filename,
          lineno: event.lineno,
          colno: event.colno
        })
      })

      window.addEventListener('unhandledrejection', (event) => {
        if (!getConsentStatus()) return
        
        trackError(event.reason, {
          type: 'unhandled_promise_rejection'
        })
      })
    }

    // Campaign tracking state
    let currentCampaign = null

    // Router tracking with campaign support
    options.router.afterEach((to, from) => {
      const handlePageView = async () => {
        try {
          if (!getConsentStatus()) return
          
          if (!isCampaignRoute(to)) {
            currentCampaign = null
            await sendPageView(to, from)
            return
          }

          const urlCampaignId = getCampaignIdFromUrl()
          if (urlCampaignId) {
            try {
              currentCampaign = await waitForCampaign(options.store, urlCampaignId)
              await sendPageView(to, from, currentCampaign)
            } catch (error) {
              console.error('Campaign tracking error:', error)
              await sendPageView(to, from)
            }
          } else {
            await sendPageView(to, from)
          }
        } catch (error) {
          console.error('Page view tracking error:', error)
        }
      }

      handlePageView()
    })

    // Cleanup
    if (typeof window !== 'undefined') {
      window.addEventListener('unload', () => {
        if (userWatcher) {
          userWatcher()
        }
      })
    }
  }
}
}
