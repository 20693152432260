<template>
    <v-snackbar
      v-model="showBanner"
      :timeout="-1"
      right
      bottom
      class="cookie-consent"
      dark
    >
      <v-card 
        flat 
        dark 
        class="consent-card"
      >
        <!-- Header -->
        <v-card-title class="px-4 pt-4">
          <span class="text-h6">Cookies on this site</span>
        </v-card-title>
    
        <!-- Main Content -->
        <v-card-text class="px-4 pb-3">
          <!-- Initial Description - Always Visible -->
          <p class="body-2 grey--text text--lighten-1 mb-4">
            We use cookies to enhance and personalise your experience. 
            Accept all cookies below, or select "Manage Cookies" to view our Privacy Policy and choose 
            which cookies we can use. Settings can be changed later by selecting the "Cookie Settings" button 
            in the footer of every page.
          </p>
    
          <!-- Detailed Cookie Settings -->
          <v-expand-transition>
            <div v-if="showDetails">
              <!-- Cookie Types -->
              <div class="cookie-settings mb-4">
                <!-- Essential Cookies -->
                <div class="cookie-section necessary mb-4">
                  <div class="d-flex align-center mb-2">
                    <div class="flex-grow-1">
                      <div class="subtitle-1 font-weight-medium d-flex align-center">
                        Strictly Necessary Cookies
                        <v-chip
                          x-small
                          label
                          color="grey darken-3"
                          class="ml-2"
                        >
                          Always Active
                        </v-chip>
                      </div>
                    </div>
                    <v-switch
                      v-model="preferences.necessary"
                      disabled
                      :input-value="true"
                      color="primary"
                      hide-details
                      dense
                      class="mt-0"
                    ></v-switch>
                  </div>
                  <p class="body-2 grey--text text--lighten-1 mb-0">
                    These cookies are necessary for the website to function and cannot be switched off. 
                    They are usually only set in response to actions made by you which amount to a request 
                    for services, such as setting your privacy preferences, logging in or filling in forms.
                  </p>
                </div>
    
                <!-- Analytics Cookies -->
                <div 
                  class="cookie-section clickable mb-4"
                  role="button"
                  tabindex="0"
                  @click="togglePreference('analytics')"
                  @keypress.enter="togglePreference('analytics')"
                >
                  <div class="d-flex align-center mb-2">
                    <div class="flex-grow-1">
                      <div class="subtitle-1 font-weight-medium">Analytics Cookies</div>
                    </div>
                    <v-switch
                      v-model="preferences.analytics"
                      color="primary"
                      hide-details
                      dense
                      class="mt-0"
                      @click.stop
                    ></v-switch>
                  </div>
                  <p class="body-2 grey--text text--lighten-1 mb-0">
                    These cookies allow us to count visits and traffic sources so we can measure and 
                    improve the performance of our site. They help us understand which pages are the 
                    most and least popular.
                  </p>
                </div>
    
                <!-- Personalization Cookies -->
                <div 
                  class="cookie-section clickable mb-4"
                  role="button"
                  tabindex="0"
                  @click="togglePreference('personalization')"
                  @keypress.enter="togglePreference('personalization')"
                >
                  <div class="d-flex align-center mb-2">
                    <div class="flex-grow-1">
                      <div class="subtitle-1 font-weight-medium">Personalization Cookies</div>
                    </div>
                    <v-switch
                      v-model="preferences.personalization"
                      color="primary"
                      hide-details
                      dense
                      class="mt-0"
                      @click.stop
                    ></v-switch>
                  </div>
                  <p class="body-2 grey--text text--lighten-1 mb-0">
                    These cookies enable personalized features and content. They may be set by us or 
                    third-party providers whose services we use on our site.
                  </p>
                </div>
    
                <!-- Policy Links -->
                <div class="d-flex mt-6 mb-4">
                  <a 
                    href="/#/dashboard/privacy-policy"
                    class="text-decoration-none caption mr-4"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </v-expand-transition>
    
          <!-- Action Buttons -->
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="handleSaveAndExit"
            >
              {{ showDetails ? 'Save & Exit' : 'Manage Cookies' }}
            </v-btn>
            <v-btn
              color="primary"
              small
              @click="acceptNecessaryOnly"
            >
              Accept Only Necessary
            </v-btn>
            <v-btn
              color="primary"
              small
              @click="acceptAll"
            >
              Allow All
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-snackbar>
    </template>
    <script>
    export default {
    name: 'CookieConsent',
    
    data: () => ({
      showBanner: false,
      showDetails: false,
      preferences: {
        necessary: true,
        analytics: false,
        personalization: false
      },
      originalPreferences: null
    }),
    
    computed: {
      isLoggedIn() {
        return !!this.$store?.state?.User?.user?.id;
      },
    
      userId() {
        return this.$store?.state?.User?.user?.id;
      }
    },
    
    watch: {
      isLoggedIn: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.checkCookieConsent();
          }
        }
      },
    
      showDetails(newValue) {
        if (newValue) {
          this.originalPreferences = JSON.parse(JSON.stringify(this.preferences));
        }
      }
    },
    
    methods: {
      togglePreference(type) {
        if (type !== 'necessary') {
          this.preferences[type] = !this.preferences[type];
        }
      },
    
      handleSaveAndExit() {
        if (this.showDetails) {
          this.savePreferences();
        } else {
          this.showDetails = true;
        }
      },
    
      checkCookieConsent() {
        try {
          this.removeNonEssentialCookies();
          const consent = localStorage.getItem('cookie_consent');
          
          if (!consent) {
            this.showBanner = true;
            return;
          }
    
          const savedData = JSON.parse(consent);
          if (this.isConsentValid(savedData)) {
            this.preferences = { 
              ...this.preferences, 
              ...savedData.preferences 
            };
            this.applyConsent(this.preferences);
          } else {
            this.showBanner = true;
            this.removeNonEssentialCookies();
          }
        } catch (error) {
          console.error('Error checking cookie consent:', error);
          this.showBanner = true;
          this.removeNonEssentialCookies();
        }
      },
    
      acceptAll() {
        this.preferences.analytics = true;
        this.preferences.personalization = true;
        this.savePreferences();
      },
    
      acceptNecessaryOnly() {
        this.preferences.analytics = false;
        this.preferences.personalization = false;
        this.savePreferences();
      },
    
      savePreferences() {
        try {
          const consentData = {
            preferences: this.preferences,
            timestamp: new Date().toISOString(),
            version: '1.0',
            userId: this.userId
          };
    
          localStorage.setItem('cookie_consent', JSON.stringify(consentData));
          this.applyConsent(this.preferences);
          this.showBanner = false;
          this.showDetails = false;
          this.$emit('consent-updated', consentData);
          this.logConsent(consentData);
        } catch (error) {
          console.error('Error saving cookie preferences:', error);
        }
      },
    
      removeNonEssentialCookies() {
        document.cookie.split(';').forEach(cookie => {
          const name = cookie.split('=')[0].trim();
          if (!this.isEssentialCookie(name)) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
          }
        });
      },
    
      isEssentialCookie(cookieName) {
        const essentialCookies = ['session_id', 'csrf_token', 'necessary_cookie'];
        return essentialCookies.includes(cookieName);
      },
    
      isConsentValid(savedData) {
        if (!savedData?.timestamp || !savedData?.version) return false;
        if (savedData.version !== '1.0') return false;
        
        const consentDate = new Date(savedData.timestamp);
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        
        return consentDate > sixMonthsAgo;
      },
    
      applyConsent(preferences) {
        this.removeNonEssentialCookies();
    
        if (preferences.analytics) {
          this.$analytics?.grantConsent();
        } else {
          this.$analytics?.revokeConsent();
        }
    
        if (preferences.personalization && this.isLoggedIn) {
          this.$analytics?.enableUserIdTracking(this.userId);
        } else {
          this.$analytics?.disableUserIdTracking();
        }
    
        this.$emit('consent-applied', preferences);
      },
    
      logConsent(consentData) {
        const logData = {
          ...consentData,
          userAgent: navigator.userAgent,
          timestamp: new Date().toISOString()
        };
        console.log('Consent logged:', logData);
      }
    },
    
    mounted() {
      this.$root.$on('show-cookie-consent', () => {
        this.showBanner = true;
        this.showDetails = true;

      });
      this.checkCookieConsent();
    },
    
    beforeDestroy() {
      this.$root.$off('show-cookie-consent');
    }
    };
    </script>
    
    <style scoped>
    .cookie-consent {
    max-width: 600px !important;
    }
    
    .cookie-consent ::v-deep .v-snack__wrapper {
    max-width: 100% !important;
    width: 100% !important;
    border-radius: 8px;
    margin: 16px;
    background: transparent !important;
    box-shadow: none;
    }
    
    .consent-card {
    background-color: #1E1E1E !important;
    border-radius: 8px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    overflow: hidden;
    }
    
    /* Cookie sections */
    .cookie-section {
    position: relative;
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    transition: background-color 0.2s ease;
    }
    
    /* Clickable sections */
    .cookie-section.clickable {
    cursor: pointer;
    user-select: none;
    }
    
    .cookie-section.clickable:hover {
    background: rgba(255, 255, 255, 0.07);
    }
    
    .cookie-section.clickable:active {
    background: rgba(255, 255, 255, 0.09);
    }
    
    /* Switch styling */
    .cookie-consent ::v-deep .v-input--switch {
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
    }
    
    .cookie-consent ::v-deep .v-input--switch .v-input__slot {
    margin: 0;
    }
    
    /* Button styling */
    .cookie-consent ::v-deep .v-btn {
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: 500;
    min-width: 110px;
    transition: opacity 0.2s ease;
    }
    
    .cookie-consent ::v-deep .v-btn:hover {
    opacity: 0.9;
    }
    
    /* Chip styling */
    .v-chip.v-chip--x-small {
    font-size: 0.625rem;
    height: 20px;
    }
    
    /* Link styling */
    a {
    color: #fff !important;
    opacity: 0.7;
    transition: opacity 0.2s ease;
    }
    
    a:hover {
    opacity: 1;
    text-decoration: underline !important;
    }
    
    /* Transitions */
    .v-expand-transition-enter-active,
    .v-expand-transition-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    }
    
    .v-expand-transition-enter,
    .v-expand-transition-leave-to {
    opacity: 0;
    transform: translateY(-8px);
    }
    
    /* Text wrapping */
    .body-2 {
    line-height: 1.5;
    word-break: normal;
    overflow-wrap: break-word;
    }
    
    /* Responsive adjustments */
    @media (max-width: 600px) {
    .cookie-consent {
      max-width: 100% !important;
    }
    
    .cookie-consent ::v-deep .v-snack__wrapper {
      margin: 0;
    }
    
    .consent-card {
      border-radius: 0;
    }
    
    .cookie-consent ::v-deep .v-btn {
      min-width: 0;
      padding: 0 12px;
    }
    
    .cookie-section {
      padding: 12px;
    }
    
    .v-card-actions {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    
    .v-card-actions .v-btn {
      margin-bottom: 8px;
    }
    }
    
    /* Animation for card appearance */
    .consent-card {
    animation: slide-up 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    @keyframes slide-up {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
    }
    </style>
    